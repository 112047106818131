import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import {styles} from "../utils/styles";
import clsx from "clsx";
import * as React from "react";
import {useEffect, useState} from "react";
import useSmartForm from "../utils/smart-form";
import * as yup from "yup";
import FormDatePicker from "../components/form-date-picker";
import Spacer from "../components/spacer";
import FormTextField from "../components/form-text-field";
import {autocompleteRenderInput, useAutocomplete, useDefaults} from "../utils/autocomplete";
import FormAutocomplete from "../components/form-autocomplete";
import {uuidv4} from "../utils/use-form";
import {Add, Delete} from "@material-ui/icons";
import NumberFormat from "react-number-format";
import {Autocomplete} from "@material-ui/lab";
import CurrencyTextField from "../components/currency-text-field";
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import axios from "axios";
import {formatPhoneNumber, serializeDate} from "../utils/utilities";
import {usStates} from "../utils/states";
import moment from "moment";
import InvoiceFileUploader from "../components/invoice-file-uploader";
import FormTelephoneInput from "../components/form-telephone-input";
import {createUserVendorData, editUserVendorData, getUserVendorData} from "../data/slices/users";
import {useHistory} from "react-router";
import FormSocialSecurityInput from "../components/form-social-security-input";

const useStyles = makeStyles({
  ...styles,
  firstRowMargin: {
    marginTop: 36,
    marginBottom: 36
  },
  headerFontSize: {
    fontSize: 28
  },
  headerInput: {
    boxSizing: 'content-box'
  },
  dataGrid: {
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-cell:focus-within': {
      outline: 'none',
    },
    display: 'flex',
  },
  tableColumnHeaders: {
    color: "#246dff",
    fontFamily: "'WotfardMedium', sans-serif",
    fontSize: 14
  },
});

const phoneRegExp = /\([1-9]\d{2}\) \d{3}-\d{4}/g;

const vendorProfileSchema = yup.object().shape({
  name: yup.string().max(25, 'Must be 25 characters or less'),
  name2: yup.string().max(25, 'Must be 25 characters or less'),
  street: yup.string().max(25, 'Must be 25 characters or less'),
  city: yup.string().max(18, 'Must be 18 characters or less'),
  state: yup.object().nullable().required('This is required'),
  zipCode: yup.string().max(5, 'Must be 5 characters').min(5, 'Must be 5 characters'),
  telephone: yup.string().matches(phoneRegExp, 'Phone number is not valid'),
  ssn: yup.string().required('This is required').max(11, 'Must be 11 characters').min(11, 'Must be 11 characters'),
  accountType: yup.object().nullable().required('This is required'),
  routingNumber: yup.string().required('This is required').max(9, 'Must be 9 characters').min(9, 'Must be 9 characters'),
  bankAccount: yup.string().required('This is required').max(17, 'Must be 17 characters or less'),
  email: yup.string().email('Must be a valid email').max(59, 'Must be 59 characters or less'),
});

const bankAccountTypes = [
  {type: 'Checking', value: 'C'},
  {type: 'Savings', value: 'S'}
];

function ManageUserVendorProfile({currentUserVendorProfile, currentUserVendorAchProfile, currentUserEmailProfile, onClose}){
  const classes = useStyles();
  const autocompleteDefaults = useDefaults();
  const isCreating = !currentUserVendorProfile;
  const currentUser = useSelector((state) => state.users.currentUser);
  const dispatch = useDispatch();
  const history = useHistory();
  const {enqueueSnackbar} = useSnackbar();

  const [isOpen, setIsOpen] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const { handleSubmit, control, formState: {errors}, register, setValue } = useSmartForm({
    schema: vendorProfileSchema,
    defaultValues: {
      "name": currentUserVendorProfile?.name || '',
      "name2": currentUserVendorProfile?.name2 || '',
      "street": currentUserVendorProfile?.street || '',
      "city": currentUserVendorProfile?.city || '',
      "state": usStates.find(x => x.value === currentUserVendorProfile?.state) || null,
      "stateInput": currentUserVendorProfile?.state || '',
      "zipCode": currentUserVendorProfile?.zipCode || '',
      "telephone": formatPhoneNumber(currentUserVendorProfile?.telephone),
      "ssn": currentUserVendorProfile?.ssn || '',
      "accountType": currentUserVendorAchProfile ? bankAccountTypes.find(x => x.value === currentUserVendorAchProfile.type) || null : null,
      "accountTypeInput": currentUserVendorAchProfile ? bankAccountTypes.find(x => x.value === currentUserVendorAchProfile.type)?.type || '' : '',
      "routingNumber": currentUserVendorAchProfile?.routingNumber || '',
      "bankAccount": currentUserVendorAchProfile?.bankAccount || '',
      "email": currentUserEmailProfile?.address || ''
    }
  });

  function handleCancel(){
    if(isCreating){
      history.goBack();
    }else{
      setIsOpen(false);
    }
  }

  async function submitProfile(data){
    setIsSaving(true);

    const formattedData = {
      userId: currentUser.id,
      vendorProfile: {
        name: data.name,
        name2: data.name2,
        street: data.street,
        city: data.city,
        state: data.state?.value,
        zipCode: data.zipCode,
        telephone: data.telephone.replace(/\D*/g, ""),
        ssn: data.ssn,
        custId: currentUser.companyId
      },
      achProfile: {
        accountType: data.accountType.value,
        routingNumber: data.routingNumber,
        bankAccount: data.bankAccount
      },
      emailProfile: {
        address: data.email
      }
    }

    if(isCreating) {
      const createReq = await dispatch(createUserVendorData({data: formattedData}));
      const {succeeded, vendorProfile, achProfile, emailProfile} = createReq.payload.data;

      if(succeeded){
        enqueueSnackbar('Profile created', {variant: 'success'});
        setIsSaving(false);
        setIsOpen(false);
      }else{
        enqueueSnackbar('An error occurred', {variant: 'error'});
        setIsSaving(false);
      }
    }else{
      const editReq = await dispatch(editUserVendorData({data: formattedData}));
      const {succeeded, vendorProfile, achProfile, emailProfile} = editReq.payload.data;

      if(succeeded){
        enqueueSnackbar('Profile updated', {variant: 'success'});
        setIsSaving(false);
        setIsOpen(false);
      }else{
        enqueueSnackbar('An error occurred', {variant: 'error'});
        setIsSaving(false);
      }
    }
  }

  return (
    <Dialog
      open={isOpen}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      fullWidth={true}
      maxWidth='md'
      TransitionProps={{ onExited: () => onClose() }}
    >
      <DialogTitle>{!isCreating ? "Edit" : "Create"} Profile</DialogTitle>
      <DialogContent className={classes.column} style={{minHeight: 350}}>
        <Typography>Your Personal Information</Typography>
        <div className={clsx(classes.row)}>
          <FormTextField
            formProps={{name: 'name', control: control}}
            className={"flex"}
            variant="outlined"
            size="small"
            label="Name (First Last)"
            autoFocus={true}
            helperText={errors.name?.message}
            required={true}
            margin='normal'
            disabled={isSaving}
          />
          <Spacer/>
          <FormTextField
            formProps={{name: 'name2', control: control}}
            className={"flex"}
            variant="outlined"
            size="small"
            label="Addl. Name"
            helperText={errors.name2?.message}
            margin='normal'
            disabled={isSaving}
          />
          <Spacer/>
          <FormTextField
            formProps={{name: 'street', control: control}}
            className={"flex"}
            variant="outlined"
            size="small"
            label="Street"
            helperText={errors.street?.message}
            required={true}
            margin='normal'
            disabled={isSaving}
          />
        </div>
        <div className={classes.row}>
          <FormTextField
            formProps={{name: 'city', control: control}}
            className={"flex"}
            variant="outlined"
            size="small"
            label="City"
            helperText={errors.city?.message}
            required={true}
            margin='normal'
            disabled={isSaving}
          />
          <Spacer/>
          <FormAutocomplete
            {...autocompleteDefaults}
            formProps={{name: "state", control: control}}
            className="flex"
            size="small"
            getOptionLabel={(x) => x.value}
            getOptionSelected={(o, v) => {
              return o.value === v.value
            }}
            options={usStates}
            onChange={(e, v) => {
              setValue('stateInput', v?.value || '');
            }}
            renderInput={autocompleteRenderInput({
              label: "State",
              name: "stateInput",
              register: register,
              error: !!errors.state || !!errors.stateInput,
              helperText: errors.state?.message || errors.stateInput?.message,
              required: true,
              margin: 'normal'
            })}
            disabled={isSaving}
          />
          <Spacer/>
          <FormTextField
            formProps={{name: 'zipCode', control: control}}
            className={"flex"}
            variant="outlined"
            size="small"
            label="Zip"
            helperText={errors.zipCode?.message}
            required={true}
            margin='normal'
            disabled={isSaving}
          />
        </div>
        <Spacer/>
        <div className={classes.row}>
          <FormTelephoneInput
            formProps={{
              name: 'telephone',
              control
            }}
            label="Phone Number"
            variant="outlined"
            required={true}
            size="small"
            className={"flex"}
            disabled={isSaving}
          />
          <Spacer/>
          <FormSocialSecurityInput
            formProps={{
              name: 'ssn',
              control
            }}
            label="Social Security Number"
            variant="outlined"
            required={true}
            size="small"
            className={"flex"}
            disabled={isSaving}
          />
          <Spacer/>
          <div className='flex'></div>
        </div>
        <Spacer/>
        <Typography>Banking Details</Typography>
        <div className={clsx(classes.row)}>
          <FormAutocomplete
            {...autocompleteDefaults}
            formProps={{name: "accountType", control: control}}
            className="flex"
            size="small"
            getOptionLabel={(x) => x.type}
            getOptionSelected={(o, v) => {
              return o === v
            }}
            options={bankAccountTypes}
            onChange={(e, v) => {
              setValue('accountTypeInput', v?.type || '');
            }}
            renderInput={autocompleteRenderInput({
              label: "Account Type",
              name: "accountTypeInput",
              register: register,
              error: !!errors.accountType,
              helperText: errors.accountType?.message,
              required: true,
              margin: 'normal'
            })}
            disabled={isSaving}
          />
          <Spacer/>
          <FormTextField
            formProps={{name: 'routingNumber', control: control}}
            className={"flex"}
            variant="outlined"
            size="small"
            label="Routing Number"
            helperText={errors.routingNumber?.message}
            required={true}
            margin='normal'
            disabled={isSaving}
          />
          <Spacer/>
          <FormTextField
            formProps={{name: 'bankAccount', control: control}}
            className={"flex"}
            variant="outlined"
            size="small"
            label="Account Number"
            helperText={errors.bankAccount?.message}
            required={true}
            margin='normal'
            disabled={isSaving}
          />
        </div>
        <Spacer/>
        <Typography>Email Details</Typography>
        <div className={clsx(classes.row)}>
          <FormTextField
            formProps={{name: 'email', control: control}}
            className={"flex"}
            variant="outlined"
            size="small"
            label="Email"
            helperText={errors.email?.message}
            margin='normal'
            disabled={isSaving}
          />
          <Spacer/>
          <div className='flex'></div>
          <Spacer/>
          <div className='flex'></div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => handleCancel()}
          disabled={isSaving}
        >
          Cancel
        </Button>
        <Button
          disabled={isSaving}
          variant="text"
          color="primary"
          onClick={handleSubmit(submitProfile)}
        >
          {isCreating ? 'Create' : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function EntryRow({row, setAllRows, invoiceDate}) {
  const classes = useStyles();
  const autocompleteDefaults = useDefaults();

  function searchRewardsProducts(searchTerm){
    return axios.get(`/rewardsProducts`, {
      params: {
        term: searchTerm,
        invoiceDate: moment(invoiceDate).format('YYYY-MM-DD')
      }
    });
  }

  const [setProductSearch, productResults, productsIsLoading] = useAutocomplete(searchRewardsProducts);
  const canEdit = !row.isApproved;

  return (
    <TableRow>
      <TableCell align='left' component="th" scope="row" width={200}>
        <Autocomplete
          {...autocompleteDefaults}
          style={{width: 200}}
          // className="flex"
          getOptionLabel={(x) => `${x.prodId}`}
          filterOptions={(options, _) => options}
          renderOption={(x) => (
            <div className={classes.column}>
              <b>{x.prodId}</b>
              <span>{x.extraDescription ? x.extraDescription : x.description}</span>
            </div>
          )}
          disabled={!canEdit}
          loading={productsIsLoading}
          options={productResults}
          getOptionSelected={(o, v) => {
            return o.prodId === v.prodId
          }}
          value={row.product}
          onInputChange={(e, value, reason) => setProductSearch(value)}
          onChange={async (e, v, r) => {
            if (v) {
              const updatedRow = {
                ...row,
                product: v,
                spiffDue: row.quantity * v.spiff,
                description: v.description
              }
              setAllRows((prev) => {
                const idx = prev.findIndex(x => x.id === row.id);
                prev[idx] = updatedRow;
                return [...prev];
              })
            } else {
              const updatedRow = {
                ...row,
                product: null,
                listPrice: 0,
                spiffDue: 0,
                description: ''
              }
              setAllRows((prev) => {
                const idx = prev.findIndex(x => x.id === row.id);
                prev[idx] = updatedRow;
                return [...prev];
              })
            }
          }}
          renderInput={(tParams) => (
            <TextField
              {...tParams}
              size="small"
              variant='outlined'
              disabled={!canEdit}
              InputProps={{
                ...tParams.InputProps,
                endAdornment: (
                  <>
                    {productsIsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {tParams.InputProps.endAdornment}
                  </>
                ),
              }}
            />

          )}
        />
      </TableCell>
      <TableCell align='right' width={200}>
        <CurrencyTextField
          value={row.quantity}
          autoNumericOptions={{
            decimalPlaces: 0,
            currencySymbol: '',
          }}
          disabled={!canEdit}
          onBlur={(e, valAsNumber) => {
            const updatedRow = {
              ...row,
              quantity: valAsNumber,
              spiffDue: valAsNumber * (row.product?.spiff || 0)
            }
            setAllRows((prev) => {
              const idx = prev.findIndex(x => x.id === row.id);
              prev[idx] = updatedRow;
              return [...prev];
            })
          }}
        />
      </TableCell>
      <TableCell align='left'>
        {row.description}
      </TableCell>
      <TableCell align='right'>
        <CurrencyTextField
          value={row.listPrice}
          autoNumericOptions={{
            decimalPlaces: 2,
            currencySymbol: '$',
          }}
          disabled={!canEdit}
          onBlur={(e, valAsNumber) => {
            const updatedRow = {
              ...row,
              listPrice: valAsNumber,
            }
            setAllRows((prev) => {
              const idx = prev.findIndex(x => x.id === row.id);
              prev[idx] = updatedRow;
              return [...prev];
            })
          }}
        />
      </TableCell>
      <TableCell align='right'>
        <NumberFormat
          thousandSeparator={true}
          value={row.spiffDue}
          displayType="text"
          decimalScale={2}
          fixedDecimalScale={true}
          prefix="$"
        />
      </TableCell>
      <TableCell align='center' width={60}>
        <Button
          disabled={!canEdit}
          onClick={() => {
            setAllRows((prev) => {
              return prev.filter(x => x.id !== row.id);
            })
          }}
        >
          <Delete color={canEdit ? 'error' : 'disabled'}/>
        </Button>
      </TableCell>
    </TableRow>
  )
}

const rewardsSchema = yup.object().shape({
  invoiceDate: yup.date('Must be a valid date').typeError('Must be a valid date').nullable().required('This is required'),
  invoiceNumber: yup.string().required('This is required').max(15, 'Must be 15 characters or less'),
  name: yup.string().required('This is required').max(25, 'Must be 25 characters or less'),
  address: yup.string().max(25, 'Must be 25 characters or less'),
  city: yup.string().max(18, 'Must be 18 characters or less'),
  state: yup.object().nullable(),
  zipCode: yup.string().max(5, 'Must be 5 characters or less'),
});

const defaultRow = {
  id: uuidv4(),
  product: null,
  quantity: 1,
  description: '',
  listPrice: 0.00,
  spiffDue: 0.00,
  isApproved: 0
}

function Rewards() {
  const classes = useStyles();
  const autocompleteDefaults = useDefaults();
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [canEditHeader, setCanEditHeader] = useState(true);
  const [isFetchingVendorData, setIsFetchingVendorData] = useState(true);
  const [canChangeInvoiceNumber, setCanChangeInvoiceNumber] = useState(true);
  const [isFetchingSpiffClaims, setIsFetchingSpiffClaims] = useState(false);
  const [rows, setRows] = useState([]);
  const [invoiceFile, setInvoiceFile] = useState(null);
  const [isInitialCreate, setIsInitialCreate] = useState(true);
  const spiffTotal = rows.reduce((acc, row) => acc + row.spiffDue, 0);
  const sellTotal = rows.reduce((acc, row) => acc + (row.quantity * row.listPrice) , 0);
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const currentUser = useSelector((state) => state.users.currentUser);
  const currentUserVendorProfile = useSelector((state) => state.users.currentUserVendorProfile);
  const currentUserVendorAchProfile = useSelector((state) => state.users.currentUserVendorAchProfile);
  const currentUserVendorEmailProfile = useSelector((state) => state.users.currentUserVendorEmailProfile);
  const hasVendorData = !!currentUserVendorProfile && !!currentUserVendorAchProfile;
  const dealerAndProfileSelected = !!currentUser.companyId && !!currentUserVendorProfile;

  useEffect(() => {
    (async () => {
      if (!hasVendorData) {
       const req = await dispatch(getUserVendorData({userId: currentUser.id}));
       const data = req.payload;
       const {vendorProfile, achProfile, emailProfile} = data;

       if(!vendorProfile || !achProfile){
         setIsEditingProfile(true);
       }
      }

      setIsFetchingVendorData(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { handleSubmit, control, reset, formState: {errors}, register, setValue, watch, getValues } = useSmartForm({
    schema: rewardsSchema,
    defaultValues: {
      "invoiceDate": null,
      "invoiceNumber": '',
      "name": '',
      "address": '',
      "city": '',
      "state": null,
      "stateInput": '',
      "zipCode": '',
    }
  });

  function handleAddRow() {
    setRows((prev) => {
      return [...prev, {...defaultRow, id: uuidv4()}];
    })
  }

  function resetData(){
    reset({
      invoiceDate: null,
      invoiceNumber: '',
      name: '',
      address: '',
      city: '',
      state: null,
      stateInput: '',
      zipCode: '',
    });
    setCanEditHeader(true);
    setCanChangeInvoiceNumber(true);
    setIsInitialCreate(true);
    setInvoiceFile(null);
    setRows([]);
  }

  async function submitSpiffClaim(data){
    setIsSubmitting(true);

    if(!currentUserVendorProfile){
      setIsEditingProfile(true);
      setIsSubmitting(false);
      enqueueSnackbar('Please create a profile', {variant: 'error'});
      return;
    }

    const filteredRows = rows.filter(x => x.product);

    if(filteredRows.length === 0){
      enqueueSnackbar('Please add at least one product', {variant: 'error'});
      setIsSubmitting(false);
      return;
    }

    if(filteredRows.some(x => x.quantity <= 0)){
      enqueueSnackbar('Quantity on entries must be greater than 0', {variant: 'error'});
      setIsSubmitting(false);
      return;
    }

    if(filteredRows.some(x => x.listPrice <= 0)){
      enqueueSnackbar('Sell price on entries must be greater than 0', {variant: 'error'});
      setIsSubmitting(false);
      return;
    }

    const formData = new FormData();

    const formattedData = {
      headerData: {...data, invoiceDate: serializeDate(data.invoiceDate), state: data.state?.value || "" },
      rows: filteredRows,
      custId: currentUser.companyId
    }

    for(const [key, value] of Object.entries(formattedData)){
      formData.append(key, JSON.stringify(value));

    }

    if(invoiceFile){
      formData.append('file', invoiceFile, invoiceFile.name);
    }else{
      enqueueSnackbar('Please attach an invoice', {variant: 'error'});
      setIsSubmitting(false);
      return;
    }

    const response = await axios.post('/rewardsSpiffs', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    if(response.data.succeeded){
      enqueueSnackbar(response.data.message, {variant: 'success'});
      resetData();
    }else{
      const rowsToAdjust = response.data.approvedRows;

      if(!!rowsToAdjust){
        setRows((prevState) => {
          let newState = prevState.map((itm) => {
              const matchingRow = rowsToAdjust.find((nr) => nr.id === itm.id)
              if(matchingRow){
                return {
                  id: matchingRow.id,
                  product: matchingRow.product,
                  quantity: matchingRow.quantity,
                  description: matchingRow.product.description,
                  listPrice: matchingRow.listPrice,
                  spiffDue: matchingRow.spiffDue,
                  isApproved: matchingRow.isApproved,
                }
              }else{
                return itm;
              }
            })
          return newState;
        })
      }
      enqueueSnackbar(response.data.message || "An error occurred", {variant: 'error'});
    }

    setIsSubmitting(false);
  }

  async function getExistingSpiffs(invoiceNumber, userId){
    setIsFetchingSpiffClaims(true);
    const request = await axios.get('/rewardsSpiffs', {
    params: {
      invoiceNumber, userId
    }});

    const data = request.data;

    if(!data.succeeded){
      enqueueSnackbar(data.message || "An error occurred", {variant: 'error'});
      setIsFetchingSpiffClaims(false);
      return;
    }

    if(data.entries.length > 0){
      const {headerData, entries} = data;
      reset({
        invoiceDate: moment(headerData.invoiceDate),
        invoiceNumber: headerData.invoiceNumber,
        name: headerData.name,
        address: headerData.address,
        city: headerData.city,
        state: usStates.find(x => x.value === headerData.state) || null,
        stateInput: headerData.state,
        zipCode: headerData.zipCode,
      });

      setRows(entries.map((x) => {
        return {
          id: x.id,
          product: x.product,
          quantity: x.quantity,
          description: x.product.description,
          listPrice: x.listPrice,
          spiffDue: x.spiffDue,
          isApproved: x.isApproved
        }
      }));

      const canEdit = !entries.some(x => x.isApproved);
      setCanEditHeader(canEdit);
      setCanChangeInvoiceNumber(false);
      setIsInitialCreate(false);
    }
    setIsFetchingSpiffClaims(false);
  }

  const watchedVars = {
    invoiceDate: watch('invoiceDate'),
  }

  return (
    <div className={clsx(classes.column, 'flex')}>
      {currentUserVendorProfile &&
        <>
          <div className={clsx(classes.column, classes.firstRowMargin)}>
            <div className={clsx(classes.row, classes.alignSpaceBetweenCenter)}>
              <Typography className={classes.headerFontSize} variant="h4">
                &nbsp;
                &nbsp;
                &nbsp;
                MAC Rewards
              </Typography>
              <div className={clsx(classes.row)}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    setIsEditingProfile(true);
                  }}
                >
                  Edit Profile
                </Button>
                <Spacer/>
                {isInitialCreate ? (
                  <InvoiceFileUploader
                    handleFile={(file) => {
                      setInvoiceFile(file);
                    }}
                    selectedFile={invoiceFile}
                  />) : (
                  <Button
                    variant='contained'
                    color='primary'
                    disabled={!(getValues('invoiceNumber') !== '' && !!currentUser.companyId)}
                    onClick={() => {
                      const queryString = new URLSearchParams({
                        invoiceNumber: getValues('invoiceNumber'),
                        custId: currentUser.companyId
                      }).toString();

                      window.open(
                        `https://portal.macgroupus.com/api/rewardsInvoice?${queryString}`
                        // `http://localhost:8080/rewardsInvoice?${queryString}`
                        ,
                        '_blank'
                      )

                    }}
                  >
                    View Invoice
                  </Button>
                )
                }
              </div>
            </div>
          </div>
          <Card className={clsx(classes.column, 'flex')}>
            <CardContent className={clsx(classes.column, 'flex')}>
              <div className={clsx(classes.row)}>
                <FormTextField
                  formProps={{name: 'invoiceNumber', control: control}}
                  className={"flex"}
                  variant="outlined"
                  size="small"
                  label="Invoice Number"
                  autoFocus={dealerAndProfileSelected}
                  onBlur={(e) => {
                    const invoiceNumber = getValues('invoiceNumber');
                    if (invoiceNumber) {
                      getExistingSpiffs(invoiceNumber, currentUser.id);
                    }
                  }}
                  helperText={errors.invoiceNumber?.message}
                  required={true}
                  disabled={isSubmitting || isFetchingSpiffClaims || !canChangeInvoiceNumber || !dealerAndProfileSelected}
                />
                <Spacer/>
                <FormDatePicker
                  className={"flex"}
                  formProps={{name: "invoiceDate", control: control}}
                  label="Invoice Date"
                  size="small"
                  required={true}
                  disabled={isSubmitting || isFetchingSpiffClaims || !canEditHeader || !dealerAndProfileSelected}
                  onChange={() => setRows([])}
                />
                <Spacer/>
                <FormTextField
                  formProps={{name: 'name', control: control}}
                  className={"flex"}
                  variant="outlined"
                  size="small"
                  label="Customer Name"
                  helperText={errors.name?.message}
                  required={true}
                  disabled={isSubmitting || isFetchingSpiffClaims || !canEditHeader || !dealerAndProfileSelected}
                />
                <Spacer/>
                <FormTextField
                  formProps={{name: 'address', control: control}}
                  className={"flex"}
                  variant="outlined"
                  size="small"
                  label="Address"
                  helperText={errors.address?.message}
                  disabled={isSubmitting || isFetchingSpiffClaims || !dealerAndProfileSelected}
                />
              </div>
              <Spacer height={20}/>
              <div className={classes.row}>
                <FormTextField
                  formProps={{name: 'city', control: control}}
                  className={"flex"}
                  variant="outlined"
                  size="small"
                  label="City"
                  helperText={errors.city?.message}
                  disabled={isSubmitting || isFetchingSpiffClaims || !dealerAndProfileSelected}
                />
                <Spacer/>
                <FormAutocomplete
                  {...autocompleteDefaults}
                  formProps={{name: "state", control: control}}
                  className="flex"
                  size="small"
                  getOptionLabel={(x) => x.value}
                  getOptionSelected={(o, v) => {
                    return o.value === v.value
                  }}
                  options={usStates}
                  onChange={(e, v, r) => {
                    setValue('stateInput', v?.value || '');
                  }}
                  renderInput={autocompleteRenderInput({
                    label: "State",
                    name: "stateInput",
                    register: register,
                    error: !!errors.state || !!errors.stateInput,
                    helperText: errors.state?.message || errors.stateInput?.message
                  })}
                  disabled={isSubmitting || isFetchingSpiffClaims || !dealerAndProfileSelected}
                />
                <Spacer/>
                <FormTextField
                  formProps={{name: 'zipCode', control: control}}
                  className={"flex"}
                  variant="outlined"
                  size="small"
                  label="Zip"
                  helperText={errors.zipCode?.message}
                  disabled={isSubmitting || isFetchingSpiffClaims || !dealerAndProfileSelected}
                />
                <Spacer/>
                <CurrencyTextField
                  autoNumericOptions={{
                    decimalPlaces: 2,
                    currencySymbol: '$',
                  }}
                  label="Sell Total"
                  value={sellTotal}
                  disabled={true}
                  className={"flex"}
                />
              </div>
              <Spacer height={20}/>
              <div className={classes.row}>
                <CurrencyTextField
                  autoNumericOptions={{
                    decimalPlaces: 2,
                    currencySymbol: '$',
                  }}
                  label="Total Spiff Due"
                  value={spiffTotal}
                  disabled={true}
                  className="flex"
                />
                <Spacer/>
                <div className='flex'></div>
                <Spacer/>
                <div className='flex'></div>
                <Spacer/>
                <div className='flex'></div>
              </div>
              <TableContainer className='flex'>
                <Table stickyHeader={true}>
                  <TableHead>
                    <TableRow>
                      <TableCell align='left' className={classes.tableColumnHeaders}>
                        Product
                      </TableCell>
                      <TableCell align='right' className={classes.tableColumnHeaders}>
                        Quantity
                      </TableCell>
                      <TableCell align='left' className={classes.tableColumnHeaders}>
                        Description
                      </TableCell>
                      <TableCell align='right' className={classes.tableColumnHeaders}>
                        Sell Price
                      </TableCell>
                      <TableCell align='right' className={classes.tableColumnHeaders}>
                        Spiff Due
                      </TableCell>
                      <TableCell align='center'>
                        <Tooltip title={!watchedVars.invoiceDate ? 'Please select an invoice date' : ''}>
                          <div>
                            <Button
                              color='primary'
                              onClick={() => handleAddRow()}
                              disabled={isSubmitting || !watchedVars.invoiceDate}
                            >
                              <Add/>
                            </Button>
                          </div>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => <EntryRow key={uuidv4()} row={row} setAllRows={setRows}
                                                 invoiceDate={watchedVars.invoiceDate}/>)}
                  </TableBody>
                </Table>
              </TableContainer>
              <Spacer/>
              <div className={clsx(classes.row, classes.alignEnd)}>
                <Button
                  onClick={() => resetData()}
                  variant='contained'
                  style={(isSubmitting || isFetchingSpiffClaims || !dealerAndProfileSelected) ? {} : {
                    color: 'white',
                    backgroundColor: 'red'
                  }}
                  disabled={isSubmitting || isFetchingSpiffClaims || !dealerAndProfileSelected}
                >
                  Cancel
                </Button>
                <Spacer/>
                <Button
                  onClick={handleSubmit(submitSpiffClaim)}
                  variant='contained'
                  color='primary'
                  disabled={isSubmitting || isFetchingSpiffClaims || !dealerAndProfileSelected}
                >
                  Submit
                </Button>
              </div>
            </CardContent>
          </Card>
        </>
      }
      {isEditingProfile &&
        <ManageUserVendorProfile
          currentUserVendorProfile={currentUserVendorProfile}
          currentUserVendorAchProfile={currentUserVendorAchProfile}
          currentUserEmailProfile={currentUserVendorEmailProfile}
          onClose={() => setIsEditingProfile(false)}
        />
      }
    </div>
  );
}

export default Rewards;