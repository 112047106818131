import clsx from "clsx";
import {
	Avatar, Badge, Collapse,
	Divider,
	Drawer,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	makeStyles, Tooltip,
	Typography, withStyles
} from "@material-ui/core";
import {useHistory, useLocation} from "react-router";
import {styles} from "../utils/styles";
import {Fragment} from "react";
import {useDispatch, useSelector} from "react-redux";
import {resetAction} from "../utils/common-actions";
import {useSnackbar} from "notistack";
import {setPromptOrderingFor} from "../data/slices/place-order";
import {SvgIcon} from "@material-ui/core";
import * as Icons from "../icons";

const axios = require('axios');

const DRAWER_WIDTH = 360;
const useStyles = makeStyles((theme) => ({
	...styles,
	drawer: {
		width: DRAWER_WIDTH,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		overflowX: 'hidden'
	},
	drawerOpen: {
		width: DRAWER_WIDTH,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		overflowX: 'hidden'
	},
	drawerClosed: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: 56,
		overflowX: 'hidden'
	},
	drawerHeading: {
		transition: "height 200ms linear",
		backgroundColor: theme.palette.primary.main
	},
	drawerHeadingOpen: {
		padding: 24,
		height: 165
	},
	drawerHeadingClosed: {
		paddingLeft: 9.5,
		paddingRight: 9.5,
		paddingTop: 12,
		height: 60
	},
	toolbarSpacer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	},
	avatar: {
		marginBottom: 8,
		transition: "height 200ms linear, width 200ms linear"
	},
	avatarOpen: {
		height: 56,
		width: 56,
	},
	avatarClosed: {
		height: 36,
		width: 36
	},
	welcomeText: {
		fontSize: 20,
		fontFamily: "WotfardMedium"
	},
	secondTierLineItem: {
		paddingLeft: theme.spacing(4)
	},
	thirdTierLineItem: {
		paddingLeft: theme.spacing(6)
	},
	fourthTierLineItem: {
		paddingLeft: theme.spacing(8)
	},
	drawerContent: {
		overflow: "hidden",
		borderRight: 'none'
	},
	list: {
		overflowX: "hidden",
		overflowY: "auto",
		backgroundColor: '#12141A'
	},
	listItemSelected: {
		backgroundColor: `${theme.palette.primary.main} !important`
	},
	listItemButton: {
		color: 'white'
	},
	listItemText: {
		color: '#C4C4C6',
		fontFamily: 'WotfardMedium !important',
	},
	listItemSelectedText: {
		color: 'white',
		fontFamily: 'WotfardMedium !important'
	},
	listItemIcon: {
		color: '#C4C4C6'
	},
	listItemSelectedIcon: {
		color: 'white'
	},
	secondTierItemList: {
		backgroundColor: '#191B20'
	},
	fourthTierItemList: {
		backgroundColor: '#25272C'
	},
	thirdTierItemText: {
		color: '#C4C4C6',
		fontFamily: 'WotfardRegular'
	},
	thirdTierItemSelectedText: {
		color: 'white',
		fontFamily: 'WotfardRegular'
	},
	noSidebarBorder: {
		borderRight: "none"
	}
}));

const StyledBadge = withStyles((theme) => ({
	badge: {
		right: 0,
		top: 2,
		border: `2px solid ${theme.palette.background.paper}`,
		padding: '0 4px',
	},
}))(Badge);

function SidebarCartIcon() {
	const shoppingCartItems = useSelector((state) => state.placeOrder.shoppingCart);

	return (
		<StyledBadge
			badgeContent={shoppingCartItems ? shoppingCartItems.reduce((prev, curr) => prev += parseInt(curr.quantity), 0) : 0}
			color="secondary">
			<SvgIcon component={Icons.ShoppingCart} viewBox="-.75 -2 24 24"/>
		</StyledBadge>
	);
}

const brandToLogoMap = {
	"Accsoon": {icon: Icons.AccsoonFavicon, iconProps: {viewBox: "-0 -2 24 24"}},
	"Benro": {icon: Icons.BenroFavicon, iconProps: {viewBox: "-2 -2 24 24"}},
	"Boya": {icon: Icons.Home, iconProps: {viewBox: "-0 -2 24 24"}},
	"Broncolor": {icon: Icons.BroncolorFavicon, iconProps: {viewBox: "-0 -2 24 24"}},
	"Calibrite": {icon: Icons.CalibriteFavicon, iconProps: {viewBox: "-2 -2 24 24"}},
	"Elinchrom": {icon: Icons.ElinchromFavicon, iconProps: {viewBox: "-2 -2 24 24"}},
	"Foba": {icon: Icons.FobaFavicon, iconProps: {viewBox: "-0 -3.75 24 24"}},
	"Gepe": {icon: Icons.GepeFavicon, iconProps: {viewBox: "-2 -2 24 24"}},
	"Gravity Backdrops": {icon: Icons.GravityFavicon, iconProps: {viewBox: "-4.5 -2 24 24"}},
	"Gravity Classic Collection": {icon: Icons.GravityFavicon, iconProps: {viewBox: "-4.5 -2 24 24"}},
	"Heliopan": {icon: Icons.HeliopanFavicon, iconProps: {viewBox: "-5 -2 24 24"}},
	"Jupio": {icon: Icons.JupioFavicon, iconProps: {viewBox: "-2 -2 24 24"}},
	"Kaiser": {icon: Icons.KaiserFavicon, iconProps: {viewBox: "-2.5 -2 24 24"}},
	"Kupo": {icon: Icons.KupoFavicon, iconProps: {viewBox: "-0 -2 24 24"}},
	"Nanlite": {icon: Icons.NanliteFavicon, iconProps: {viewBox: "-0 -2 24 24"}},
	"Novoflex": {icon: Icons.NovoflexFavicon, iconProps: {viewBox: "-4 -2 24 24"}},
	"Phottix": {icon: Icons.PhottixFavicon, iconProps: {viewBox: "-2 -2 24 24"}},
	"Rotatrim": {icon: Icons.RotatrimFavicon, iconProps: {viewBox: "-2.25 -2 24 24"}},
	"Saramonic": {icon: Icons.SaramonicFavicon, iconProps: {viewBox: "-0 -2 24 24"}},
	"Sekonic": {icon: Icons.SekonicFavicon, iconProps: {viewBox: "-5.5 -2 24 24"}},
	"Shimoda": {icon: Icons.ShimodaFavicon, iconProps: {viewBox: "-1.75 -2 24 24"}},
	"Tenba": {icon: Icons.TenbaFavicon, iconProps: {viewBox: "-4 -2 24 24"}},
	"Toyo": {icon: Icons.ToyoFavicon, iconProps: {viewBox: "-4.5 -2 24 24"}}
};

const PURCHASER_ROLES = ["Super Administrator", "Sales Manager", "Salesman", "Administrator", "Buyer", "EPP"];
const MANAGER_ROLES = ["Super Administrator", "Administrator", "Sales Manager", "Salesman"];
const INTERNAL_ROLES = ["Super Administrator", "Sales Manager", "Salesman", "Brand Manager"];
const ORDERER_ROLES = ["Super Administrator", "Administrator", "Brand Manager", "Sales Manager", "Salesman", "Buyer", "EPP"];

const combinedSidebarItems = [
	{text: "Home", icon: Icons.Home, iconProps: {viewBox: "-2.75 -2 24 24"}, requireLogin: true, url: '/home'},
	{
		text: "Place an Order",
		icon: Icons.PlaceAnOrder,
		iconProps: {viewBox: "-2.25 -2 24 24"},
		requireLogin: true,
		rolesAllowed: PURCHASER_ROLES,
		subItems: [
			// {text: "Announcement", icon: Icons.Announcements, iconProps: {viewBox: "-1 -2 24 24"}, requireLogin: true, url: '/placeOrder/home'},
			{text: "Shopping Cart", icon: SidebarCartIcon, requireLogin: true, url: '/placeOrder/shoppingCart'},
			{
				text: "Quick Order",
				icon: Icons.QuickOrder,
				iconProps: {viewBox: "0 -2 24 24"},
				requireLogin: true,
				url: '/placeOrder/quick'
			},
			{
				text: "Order History",
				icon: Icons.OrderHistory,
				iconProps: {viewBox: "-1 -2 24 24"},
				requireLogin: true,
				url: '/placeOrder/history'
			},
			{
				text: "Select Dealer",
				icon: Icons.SwitchDealer,
				iconProps: {viewBox: "-.5 -2 24 24"},
				actionIndex: 2,
				requireLogin: true,
				rolesAllowed: INTERNAL_ROLES
			},
			{
				text: "Special Opportunity",
				icon: Icons.SpecialOpportunities,
				iconProps: {viewBox: "-.5 -2 24 24"},
				requireLogin: true,
				rolesAllowed: PURCHASER_ROLES.slice(0, 5), //everyone but EPP
				url: '/placeOrder/specialOpportunity'
			},
			{
				text: "Order by Brand",
				icon: Icons.OrderByBrand,
				iconProps: {viewBox: "-4 -2 24 24"},
				requireLogin: true,
				categoriesAsChildren: true
			}
		]
	},
	{
		text: "Brand Information",
		icon: Icons.BrandInformation,
		iconProps: {viewBox: "-2 -2 24 24"},
		requireLogin: true,
		rolesAllowed: ["Administrator", "Brand Manager", "Buyer", "Contact", "Sales Manager", "Salesman", "Super Administrator", "User"],
		brandsAsChildren: true
	},
	{
		text: "Price Lists & Promotions",
		icon: Icons.PriceListsAndPromotions,
		iconProps: {viewBox: "-3 -2 24 24"},
		subItems: [
			{
				text: "Current Price Lists",
				icon: Icons.Offsite,
				iconProps: {viewBox: "-2 -2 24 24"},
				requireLogin: true,
				openInNewTab: true,
				url: 'https://macgroupus.box.com/s/n69xp2ex9zvrasfqsmcqir6kqvu2vboi'
			},
			{
				text: "Promotions",
				icon: Icons.Offsite,
				iconProps: {viewBox: "-2 -2 24 24"},
				requireLogin: true,
				openInNewTab: true,
				url: 'https://macgroupus.box.com/s/1wirx20o6ai1otj19abjlmoh278y9rom'
			}
		]
	},
	{
		text: "What's New What's Hot",
		icon: Icons.Wnwh,
		iconProps: {viewBox: "-2 -2 24 24"},
		requireLogin: true,
		url: '/whatsNewWhatsHot'
	},
	// {text: "Account Settings", icon: Icons.AccountSettings, iconProps: {viewBox: "-2 -2 24 24"}, requireLogin: true, url: '/accountSettings'},
	{
		text: "Administrator Settings",
		icon: Icons.AdministratorSettings,
		iconProps: {viewBox: "-3.75 -2 24 24"},
		subItems: [
			{
				text: "Manage Announcement",
				icon: Icons.DealerBulletins,
				requireLogin: true,
				rolesAllowed: ["Super Administrator"],
				url: '/manageAnnouncement'
			},
			// {
			// 	text: "Manage Brands",
			// 	icon: Icons.ManageBrands,
			// 	iconProps: {viewBox: "-3.25 -2 24 24"},
			// 	requireLogin: true,
			// 	rolesAllowed: ["Super Administrator", "Brand Manager"],
			// 	url: '/manageBrands'
			// },
			{
				text: "Manage Users",
				icon: Icons.ManageUsers,
				iconProps: {viewBox: "-.5 -2 24 24"},
				requireLogin: true,
				rolesAllowed: MANAGER_ROLES,
				url: '/manageUsers'
			}
		]
	},
	{
		text: "Service & Support",
		icon: Icons.ServiceSupport,
		iconProps: {viewBox: "0 -1.75 31 24"},
		requireLogin: true,
		subItems: [
			// {text: "Returns"},
			{text: "Shipping & Delivery", url: '/shippingAndDeliveryInfo'},
			// {text: "Warranty"},
			// {text: "Privacy Policy"},
			// {text: "Payments & Pricing"},
			// {text: "Ordering Process"}
		]
	},
	{
		text: "PhotoVideo EDU",
		icon: Icons.PhotoVideoEDU,
		iconProps: {viewBox: "0 0 51.4 40"},
		requireLogin: true,
		rolesAllowed: ["Administrator", "Brand Manager", "Buyer", "Sales Manager", "Salesman", "Super Administrator"],
		subItems: [
			// {text: "Returns"},
			// {
			// 	text: "Report PhotoVideo EDU Sales",
			// 	url: '/photoVideoEDU/reportSales',
			// 	icon: Icons.PricesAndProductInformation,
			// 	iconProps: {viewBox: "-2 -2 42 42"}
			// },
			{
				text: "Submit EDU Rebates",
				url: '/photoVideoEDU/rebates',
				icon: Icons.Rebate,
				iconProps: {viewBox: "0 -2 24 24"},
				rolesAllowed: ORDERER_ROLES
			},
			// {text: "Warranty"},
			// {text: "Privacy Policy"},
			// {text: "Payments & Pricing"},
			// {text: "Ordering Process"}
		]
	},
	{
		text: "Mac Rewards",
		icon: Icons.Reward,
		iconProps: {viewBox: "0 0 30 30"},
		requireLogin: true,
		rolesAllowed: ["Administrator", "Brand Manager", "Buyer", "Contact", "Sales Manager", "Salesman", "Super Administrator", "User"],
		subItems: [
			{
				text: "Claim Rewards",
				url: '/rewards',
				icon: Icons.Money,
				iconProps: {viewBox: "-2 -2 20 20"}
			},
			{
				text: "Rewards History",
				url: '/rewardsHistory',
				icon: Icons.OrderHistory,
				iconProps: {viewBox: "0 -2 24 24"}
			},
		]
	},
	{isSpacer: true},
	{
		text: "Log Out",
		icon: Icons.Logout,
		iconProps: {viewBox: "-0 -2 24 24"},
		actionIndex: 1,
		requireLogin: true,
		dividerBefore: true,
		url: '/login'
	}
];

function DisplayMenuItem({
													 item,
													 filteredBrands,
													 filteredCategories,
													 expandedItems,
													 user,
													 location,
													 toggleExpansion,
													 handleBrandClick,
													 handleCategoryClick,
													 handleAction,
													 sidebarOpen,
													 orderingFor
												 }) {
	const classes = useStyles();

	if (item.isSpacer) {
		return <div className="flex"/>;
	} else if (item.brandsAsChildren) {
		item.subItems = filteredBrands.filter((brand) => {
			const isBroncolor = brand.text === "Broncolor";
			const userDealerTypeMatches = (user.dealerType?.indexOf('5') ?? -1) !== -1;
			const orderingForTypeMatches = (orderingFor?.dealerType?.indexOf('5') ?? -1) !== -1;
			const alwaysAllowedRoles = ["Brand Manager", "Sales Manager", "Super Administrator"];

			if (isBroncolor) {
				return userDealerTypeMatches || orderingForTypeMatches || alwaysAllowedRoles.includes(user.userRole);
			} else {
				return true;
			}
		});
	} else if (item.categoriesAsChildren) {
		item.subItems = filteredCategories.filter((brand) => {
			const isBroncolor = brand.text === "Broncolor";
			const userDealerTypeMatches = (user.dealerType?.indexOf('5') ?? -1) !== -1;
			const orderingForTypeMatches = (orderingFor?.dealerType?.indexOf('5') ?? -1) !== -1;

			if (isBroncolor) {
				return userDealerTypeMatches || orderingForTypeMatches;
			} else {
				return true;
			}
		});
	}

	const entryExpanded = !!expandedItems[item.text];
	const roleAllowed = item.rolesAllowed ? user ? item.rolesAllowed.includes(user.userRole) : false : true;
	const rewardsVisible = item.text === "Mac Rewards" ? user && user.isSpiffQualified : true;
	const anySubsVisible = item.subItems ? item.subItems.some((it) => {
		const subRoleAllowed = it.rolesAllowed ? user ? it.rolesAllowed.includes(user.userRole) : false : true;
		return !it.requireLogin || (it.requireLogin && user && subRoleAllowed);
	}) : false;
	const displayEntry = !item.requireLogin ||  (item.requireLogin && user && roleAllowed && rewardsVisible);
	const itmSelected = (location.pathname === item.url) || (location.pathname === `/placeOrder/category/${item.categoryNumber}`) ||
		(location.pathname === `/brandInformation/${item.brandNumber}`);

	return (
		displayEntry && (!item.subItems || (item.subItems && anySubsVisible)) ?
			<Fragment key={`list-item-${item.text}`}>
				{item.dividerBefore && <Divider/>}
				<ListItem
					className={clsx(classes.listItemButton, {
						[classes.secondTierLineItem]: item.level === 1,
						[classes.thirdTierLineItem]: item.level === 2,
						[classes.fourthTierLineItem]: item.level > 2
					})}
					classes={{
						selected: classes.listItemSelected
					}}
					button
					selected={itmSelected}
					onClick={() => {
						if (item.subItems) {
							toggleExpansion(item);
						} else if (item.isBrand) {
							handleBrandClick(item.brandNumber);
						} else if (item.isCategory) {
							handleCategoryClick(item.categoryNumber);
						} else {
							handleAction(item);
						}
					}}>
					{item.icon
						? sidebarOpen
							? (
								<ListItemIcon className={clsx({
									[classes.listItemIcon]: !itmSelected,
									[classes.listItemSelectedIcon]: itmSelected
								})}>
									<SvgIcon component={item.icon} {...item.iconProps}/>
								</ListItemIcon>
							) : (
								<Tooltip title={item.text} placement="right" arrow={true}>
									<ListItemIcon
										className={clsx({
											[classes.listItemIcon]: !itmSelected,
											[classes.listItemSelectedIcon]: itmSelected
										})}>
										<SvgIcon component={item.icon} {...item.iconProps}/>
									</ListItemIcon>
								</Tooltip>
							)
						: null
					}
					<ListItemText
						classes={{
							primary: clsx({
								[classes.listItemText]: !itmSelected && item.level <= 1,
								[classes.listItemSelectedText]: itmSelected && item.level <= 1,
								[classes.thirdTierItemText]: !itmSelected && item.level > 1,
								[classes.thirdTierItemSelectedText]: itmSelected && item.level > 1
							})
						}}
						primary={item.text}/>
					{item.subItems && (entryExpanded ?
						<SvgIcon className={classes.listItemIcon} component={Icons.ArrowUp} viewBox='-4.5 -8.25 24 24'/> :
						<SvgIcon className={classes.listItemIcon} component={Icons.ArrowDown} viewBox='-4.5 -8.25 24 24'/>)}

				</ListItem>
				{item.subItems &&
				<Collapse in={entryExpanded} timeout="auto" unmountOnExit>
					<List
						className={clsx({
							[classes.secondTierItemList]: item.level === 0,
							[classes.fourthTierItemList]: item.level > 1
						})}
						component="div"
						disablePadding
					>
						{item.subItems.map((subItem) => {
							subItem.level = item.level + 1;

							return <DisplayMenuItem
								key={`sub-item-${subItem.text}`}
								item={subItem}
								filteredBrands={filteredBrands}
								filteredCategories={filteredCategories}
								expandedItems={expandedItems}
								user={user}
								location={location}
								toggleExpansion={toggleExpansion}
								handleBrandClick={handleBrandClick}
								handleCategoryClick={handleCategoryClick}
								handleAction={handleAction}
								sidebarOpen={sidebarOpen}
								orderingFor={orderingFor}
							/>
						})}
					</List>
				</Collapse>}
			</Fragment> : null
	);
}

function Sidebar({sidebarOpen, expandedItems, toggleExpansion}) {
	const classes = useStyles();
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const orderingFor = useSelector((state) => state.placeOrder.orderingFor);
	const user = useSelector((state) => state.users.currentUser);
	// const brandPermissions = user.brandPermissions;
	const token = useSelector((state) => state.users.currentUserToken);
	const brands = useSelector((state) => state.brands.brands);
	const categories = useSelector((state) => state.categories.categories);
	const {enqueueSnackbar} = useSnackbar();

	const brandsAfterPermissions = brands.filter((b) => {
		// if (brandPermissions[b.id]) {
		// 	for (const key in brandPermissions[b.id]) {
		// 		if (brandPermissions[b.id][key]) {
		// 			return true;
		// 		}
		// 	}
		// 	return false;
		// } else {
		// 	return false;
		// }
		// per Chris they want to be able to see the assets for broncolor even if they can't order it
		// return !(b.name === "Broncolor" && (user.dealerType?.indexOf('5') ?? -1) === -1 && (orderingFor?.dealerType?.indexOf('5') ?? -1) === -1);
		return true;
	});

	const filteredBrands = brandsAfterPermissions.map((b, index) => {
		return {
			text: b.name,
			icon: brandToLogoMap[b.name]?.icon,
			iconProps: brandToLogoMap[b.name]?.iconProps,
			requireLogin: true,
			isBrand: true,
			brandNumber: b.number
		};
	});

	const filteredCategories = brandsAfterPermissions.map((b, index) => {
		let matchingCategories = categories.filter((c) => {
			return c.brand === b.id;
			// return c.brand === b.id && brandPermissions[b.id]?.[c.id];
		}).sort((c1, c2) => c1.displayOrder - c2.displayOrder);

		if (matchingCategories.length === 1) {
			return {
				text: matchingCategories[0].name,
				icon: brandToLogoMap[matchingCategories[0].name]?.icon,
				iconProps: brandToLogoMap[matchingCategories[0].name]?.iconProps,
				requireLogin: true,
				isCategory: true,
				categoryNumber: matchingCategories[0].number,
				brandNumber: matchingCategories[0].brand
			};
		} else {
			return {
				text: b.name,
				icon: brandToLogoMap[b.name]?.icon,
				iconProps: brandToLogoMap[b.name]?.iconProps,
				subItems: matchingCategories.map((c) => {
					return {
						text: c.name,
						icon: null,
						requireLogin: true,
						isCategory: true,
						categoryNumber: c.number,
						brandNumber: b.number
					};
				})
			}
		}
	});

	async function handleAction(item) {
		if (item.url) {
			if (item.openInNewTab) {
				window.open(item.url, '_blank');
			} else {
				history.push(item.url);
			}
		}

		switch (item.actionIndex) {
			case 1:
				if (token) {
					try {
						await axios.post('/revokeToken', {
							token: token
						});
					} catch (e) {
						enqueueSnackbar("Token revocation error!", {
							anchorOrigin: {
								horizontal: "center",
								vertical: "bottom"
							},
							variant: "error"
						});
					}
				}
				sessionStorage.clear();
				dispatch(resetAction());
				break;
			case 2:
				dispatch(setPromptOrderingFor(true));
				break;
			default:
		}
	}

	function handleBrandClick(brandId) {
		history.push(`/brandInformation/${brandId}`);
	}

	function handleCategoryClick(categoryId) {
		history.push(`/placeOrder/category/${categoryId}`);
	}

	return (
		<Drawer
			variant="permanent"
			className={clsx(classes.drawer, {
				[classes.drawerOpen]: sidebarOpen,
				[classes.drawerClosed]: !sidebarOpen
			})}
			classes={{
				paper: clsx("MuiPaper-elevation16", classes.column, classes.drawerContent, {
					[classes.drawerOpen]: sidebarOpen,
					[classes.drawerClosed]: !sidebarOpen
				}),
				paperAnchorDockedLeft: clsx({
					[classes.noSidebarBorder]: location.pathname === "/home"
				})
			}}
		>
			<div className={classes.toolbarSpacer}/>
			{user &&
			<div
				className={clsx(classes.column, classes.drawerHeading, !sidebarOpen && classes.drawerHeadingClosed, sidebarOpen && classes.drawerHeadingOpen)}>
				<div className={clsx(classes.row)}>
					<Avatar
						className={clsx(classes.avatar, !sidebarOpen && classes.avatarClosed, sidebarOpen && classes.avatarOpen)}/>
				</div>
				{sidebarOpen && [
					<Typography className={clsx(classes.white, classes.welcomeText)} key="user-name-header" variant="h6"
											noWrap={true}>
						Welcome, {user.firstName} {user.lastName}!
					</Typography>,
					<Typography className={clsx(classes.capitalize, classes.white)} key="user-company-header" variant="subtitle2"
											noWrap={true}>
						{user.companyName?.toLowerCase() ?? "MAC Group"} {user.userRole === "EPP" ? "(EPP Mode)" : ""}
					</Typography>
				]}
			</div>}
			<List
				className={clsx("flex", classes.column, classes.list, {
					[classes.hideScrollbar]: !sidebarOpen
				})}
				disablePadding={true}
			>
				{combinedSidebarItems.map((itm) => {
					itm.level = 0;

					return <DisplayMenuItem
						key={`top-level-item-${itm.text}`}
						item={itm}
						filteredBrands={filteredBrands}
						filteredCategories={filteredCategories}
						expandedItems={expandedItems}
						user={user}
						location={location}
						toggleExpansion={toggleExpansion}
						handleBrandClick={handleBrandClick}
						handleCategoryClick={handleCategoryClick}
						handleAction={handleAction}
						sidebarOpen={sidebarOpen}
						orderingFor={orderingFor}
					/>;
				})}
			</List>
		</Drawer>
	);
}

export default Sidebar;